<template>
    <div class="text-center">
        <v-menu
        :close-on-content-click="false"
        :nudge-width="500"
        offset-x
        >
        <template v-slot:activator="{ on }">
            <v-btn color="black" text icon v-on="on">
            <v-icon>info</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-list dense>
            <v-list-item
                v-for="item in listaStatus"
                :key="item.day"
            >
                <v-list-item-title class="subtitle-2 py-3">
                  {{ item.text }}:
                </v-list-item-title>

                <v-list-item-subtitle width="20%" class="font-weight-medium">
                  {{ getMoney(item.valor) }}
                </v-list-item-subtitle>

                <v-list-item-icon class="font-weight-black">
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
            </v-list-item>
            </v-list>
        </v-card>
        </v-menu>
    </div>
</template>

<script>
import { getMoney } from '../../common/functions/helpers';

export default {
  data() {
    return {
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoVerbaVariavelListagem(this.$resource),
      listaStatus: [
        {
          text: this.$t('status_entidade.aguardando_apuracao'),
          chave: 'AGUARDANDO_APURACAO',
          valor: 0,
          icon: 'add',
        },
        {
          text: this.$t('status_entidade.aguardando_aprovacao'),
          chave: 'AGUARDANDO_APROVACAO',
          valor: 0,
          icon: 'add',
        },
        {
          text: this.$t('status_entidade.em_analise'),
          chave: 'EM_ANALISE',
          valor: 0,
        },
      ],
    };
  },
  props: {
    codAcao: {
      type: Number,
      default: null,
    },
  },
  methods: {
    getMoney,
    buscarDadosPopoverNd() {
      this.apuracaoAcaoListagemResource.buscarDadosPopoverNd({ idAcao: this.codAcao }).then((response) => {
        this.listaStatus.map((item) => {
          response.data.forEach((e) => {
            if (item.chave === e.status) {
              item.valor = e.valor;
            }
          });
          return item;
        });
      });
    },
  },
  mounted() {
    this.buscarDadosPopoverNd();
  },
};
</script>
