<template>
  <div>
    <apuracao-acao-filtro
        @ApuracaoAcaoFiltro__AplicaFiltros="aplicarFiltros">
    </apuracao-acao-filtro>

    <apuracao-acao-lista>
    </apuracao-acao-lista>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import ApuracaoAcaoFiltro from './ApuracaoAcaoFiltro';
import ApuracaoAcaoLista from './ApuracaoAcaoLista';

export default {
  name: 'ApuracaoAcao',
  components: {
    ApuracaoAcaoFiltro,
    ApuracaoAcaoLista,
  },
  data() {
    return {
      aplicarFiltroNoCarregamento: true,
      filtros: null,
    };
  },
  computed: {
    ...generateComputed('APU_ACAO', [
      'canAccessPage',
    ]),
    ...generateComputed('ACESSO_CLIENTE', [
      'getAllRoles',
    ]),
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroApuracaoAcao',
    ]),
    accessForbidden() {
      this.$router.push({ name: 'inicio' });
      this.$toast(this.$t('message.acesso_negado'));
    },
    aplicarFiltros(filtrosAplicados) {
      const filtros = {
        ...filtrosAplicados,
      };
      this.filtros = filtros;
      this.setFiltroApuracaoAcao(filtros);
    },
  },
  beforeMount() {
    if (!this.$route.params || !this.$route.params.filtrosAplicados) {
      this.setFiltroApuracaoAcao(null);
    }

    const { origem } = this.$route.params;
    if (origem) {
      this.omiteBreadcrumbs = true;
    }
  },
  mounted() {
    if (!this.canAccessPage) {
      if (!this.getAllRoles
          || !this.getAllRoles.filter((el) => el.startsWith('ACESSO_CLIENTE')).length) {
        window.setTimeout(() => this.accessForbidden(), 1E3);
      }
    }
  },
};
</script>
<style scoped>
</style>
